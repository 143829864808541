import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import { Text, Box } from '@chakra-ui/core'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Box my="10">
        <Text fontSize="3xl" mb="4">
          <h1>404 – Page not found</h1>
        </Text>
        <Text fontSize="md" mb="2">
          Thanks for visiting the FrogID website! The page you are looking for may have been
          unpublished or moved to a new location.
        </Text>
        <Text>
          <p>Please try visiting another page.</p>
        </Text>
      </Box>
    </Container>
  </Layout>
)

export default NotFoundPage
